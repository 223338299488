import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ReferralCard from '../../card/ReferralCard';
import ButtonCtaReferral from '../../button/ButtonCtaReferral';
import CONTACT_CONFIG from '../../../configs/contactConfig';
import { CUSTOM_EVENT, pushEvent } from '../../../configs/clevertap';
import Button from '../../button/Button';
import GTM_TRACKERS from '../../../../constants/gtm-trackers';

export default function Advantage() {
  return (
    <ReferralCard title="Keuntungan yang Bisa Kamu Dapatkan">
      <div className="flex flex-col justify-center gap-y-8 m-4 md:mx-12 md:my-4">
        <div className="flex flex-col items-center gap-y-6">
          <StaticImage
            src="../../../images/referral/detail/advantage-1.webp"
            alt="Produk Terjamin"
            objectFit="contain"
            className="w-32 h-32"
          />
          <div className="flex flex-col gap-y-2">
            <h5 className="text-sm font-bold text-center">
              Apakah Produk ini Terjamin?
            </h5>
            <p className="text-sm font-normal text-center">
              DepositoBPR by Komunal adalah marketplace produk-produk deposito Bank Perekonomian Rakyat (BPR) dari seluruh Indonesia.
            </p>
          </div>
          {/* eslint-disable-next-line max-len */}
          <div className="flex flex-col  md:flex-row md:gap-x-2 md:border-[1px] md:border-secondary-200 md:rounded-xl md:p-4 md:divide-x md:divide-dashed md:divide-secondary-200">
            <div className="flex flex-col gap-y-1 md:w-1/3 md:p-3">
              <h6 className="text-sm font-bold text-center">#LebihUntung</h6>
              <p className="text-sm font-normal text-center">
                Bunga Deposito di BPR lebih tinggi hingga 6,75% p.a. dibandingkan dengan Bank Umum.
              </p>
            </div>
            <div className="flex flex-col gap-y-1 md:w-1/3 md:p-3">
              <h6 className="text-sm font-bold text-center">#LebihAman</h6>
              <p className="text-sm font-normal text-center">
                Aplikasi DepositoBPR Terdaftar dan diawasi oleh OJK No. S-576/IK.01/2024.
                Seluruh simpanan deposito di BPR juga dijamin oleh Lembaga Penjamin Simpanan (LPS).
              </p>
            </div>
            <div className="flex flex-col gap-y-1 md:w-1/3 md:p-3">
              <h6 className="text-sm font-bold text-center">#LebihPraktis</h6>
              <p className="text-sm font-normal text-center">
                Cukup sekali daftar untuk buka deposito di ratusan BPR terseleksi di seluruh Indonesia.
              </p>
            </div>
          </div>
        </div>
        <div className="md:flex md:justify-center md:gap-x-4">
          <div className="flex flex-col items-center gap-y-4 md:w-1/2">
            <StaticImage
              src="../../../images/referral/detail/advantage-2.webp"
              alt="Keuntungan Komisi"
              objectFit="contain"
              className="w-32 h-32"
            />
            <h5 className="text-sm font-bold text-center">
              Kenapa Program Ini Cocok Untuk Bisnis Sampingan?
            </h5>
            <p className="text-sm font-normal text-center">
              Banyak Promo Cashback dan Voucher. Mengajak teman jadi lebih mudah karena banyak keuntungan untuk mereka.
            </p>
          </div>
          <div className="flex flex-col items-center gap-y-4 md:w-1/2">
            <StaticImage
              src="../../../images/referral/detail/advantage-3.webp"
              alt="Tercatat OJK"
              objectFit="contain"
              className="w-32 h-32"
            />
            <h5 className="text-sm font-bold text-center">
              Dukungan Marketing Tools
            </h5>
            <p className="text-sm font-normal text-center">
              Daftar dan dapatkan hanya dengan cara download. Menjelaskan program jadi lebih mudah.
            </p>
          </div>
        </div>
        <div className={`flex flex-col items-center gap-y-6 ${GTM_TRACKERS.REFERRAL.DAFTAR_SEKARANG}`}>
          <Button
            onClick={() => pushEvent(CUSTOM_EVENT.DAFTAR_SEKARANG_DETAIL)}
            isInternal
            text="Daftar Sekarang"
            href="/referral/register"
            className="w-full py-4 md:w-60"
          />
          <div className="flex flex-col gap-y-2">
            <p className="text-sm font-normal text-center">
              Ingin Punya Teman Sharing dan Diskusi
            </p>
            <p className="text-sm font-normal text-center">
              Bisa join komunitas untuk mempelajari program ini dan makin pintar berjualan.
            </p>
          </div>
          <ButtonCtaReferral
            onClick={() => {
              pushEvent(CUSTOM_EVENT.GABUNG_KOMUNITAS_REFERRAL);
              window.open(CONTACT_CONFIG.SOCMED.TELEGRAM, '_blank');
            }}
            text="Gabung Komunitas Referral"
            className="md:w-80"
          />
        </div>
      </div>
    </ReferralCard>
  );
}
